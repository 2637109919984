<template>
    <div>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>Student List</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row class='pl-4'>
                <v-col md="6">
                    <label><b>Academic Year: </b>{{ay}}</label>
                </v-col>
                <v-col md="6">
                    <label><b>Placement Type: </b>{{placementtype}}</label>
                </v-col>
                <v-col md="12">
                    <b><v-checkbox :v-model="selectall" v-bind:label="'Select All'"  @change="selectAll();" ></v-checkbox></b>
                </v-col>
                <v-col md="2" v-for="item in heads" :key="item.name">
                    <input style="width:18px;height:18px;" type="checkbox" :id="item.name" v-model="item.checked" :checked="item.checked" > {{item.name}}
                </v-col>
                <v-col sm="12">
                    <v-btn class="mt-6" color="primary darken-1" @click="fetchReport()">Fetch</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="learnerlist.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
            <v-data-table  id="exceltable"  :headers="headers" :items="learnerlist"  class="elevation-1" :search="search" >
                <template v-slot:top class="red">
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Stduent List</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                        <span style="width: 20px"></span>
                    </v-toolbar>
                </template>
            </v-data-table>
        </div>
        <v-overlay :value="overlay">
           <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",

        heads : [],
        ay: null,
        placementtype:null,

        instituteid:null,
        programtypeid:null,
        programid:null,
        ayid:null,
        placementtypeid:null,
        yearmasterid:null,

        learnerlist: [],
        selectall : false,
        headers: [],
    }),

    mounted() {
        this.instituteid = this.$route.params.orgid;
        this.programtypeid = this.$route.params.ptid;
        this.programid = this.$route.params.progid;
        this.ayid = this.$route.params.ayid;
        this.placementtypeid = this.$route.params.placementtypeid;
        this.yearmasterid = this.$route.params.yrid;

        const data = {
            ay:this.ayid,
            placementtype:this.placementtypeid,
        };
        axios
            .post("/TPO/getStudentListRegDashboardHeads", data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.heads = res.data.heads;
                    this.ay = res.data.ay;
                    this.placementtype = res.data.placementtype;
                } else {
                    console.log("error fetching data!");
                }
            });
    },

    methods: {
         fetchReport() { 
            this.learnerlist = []
            var headArray = [];
            for(var i = 0; i < this.heads.length; i++){
                if(this.heads[i].checked)
                    headArray.push(this.heads[i].name)
            }

            if (headArray.length == 0) {
                this.showSnackbar("red", "Please select Fields First !!");
            } else {
                this.overlay = true;
                const data = {
                    instituteid:this.instituteid,
                    programtypeid:this.programtypeid,
                    programid:this.programid,
                    ay:this.ayid,
                    placementtype:this.placementtypeid,
                    yearmasterid:this.yearmasterid,
                    heads : headArray,
                    
                };
                axios
                    .post("TPO/getStudentDataComponentWise",data)
                    .then((res) => {
                        this.overlay = false;
                        if (res.data.msg == "200") {
                            this.learnerlist = res.data.learnerlist;
                            if(this.learnerlist.length < 1){
                                this.showSnackbar("red", "Data Not Found");
                            }else{
                                this.headers = [];
                                this.headers.push({ text: 'Sr.No.', value: 'srno', align: 'left', sortable: true});  
                                for(var i = 0; i< headArray.length; i++){
                                    var temp = { text: headArray[i], value: headArray[i], align: 'left', sortable: true}; 
                                    this.headers.push(temp);  
                                }  
                            }
                        } else {
                            this.showSnackbar("red", res.data.error);
                        }
                    }).catch((error) => {
                        this.overlay = false;
                        window.console.log(error);
                    }).finally(() => {
                        this.overlay = false;
                    });
            }
        },

         selectAll(){
            this.selectall = !this.selectall;
            //console.log(this.selectall)
            for(var i = 0; i < this.heads.length; i++){
                if(this.selectall){
                    this.heads[i].checked = true;
                    document.getElementById(this.heads[i].name).checked = true;
                }else{
                    this.heads[i].checked = false;
                    document.getElementById(this.heads[i].name).checked = false;
                }
            }
        },

        exportexcel() {    
            $("#exceltable").table2excel({  
            name: "Worksheet Name",
            filename: "Student_reg_list", //do not include extension
            fileext: ".xls" // file extension
            });        
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};
</script>
<style scoped>
    .required{
        color:red;
        font-size:14px;
        font-style:bold;
    }

    .tc-title {
        background-image: linear-gradient(-90deg, #fff, #057996);
        color: #fff;
        border-radius: 3px;
    }
</style>    